import { Controller } from "@hotwired/stimulus";
import Flickity from "flickity";

export default class extends Controller {
  static targets = [
    "announcement",
    "slide",
    "slideWrapper",
  ];

  static values = {
    index: Number,
    maxIndex: Number,
  };

  static buttonTargets = [
    "nextButton",
    "nextButtonLabel",
    "previousButton",
    "previousButtonLabel",
  ];

  connect() {
    if (this.hasNextButtonTarget) {
      targets.push(buttonTargets);
    }

    this.flickity = new Flickity(this.slideWrapperTarget, {
      cellAlign: "left",
      draggable: true,
      freeScroll: false,
      pageDots: false,
      prevNextButtons: false,

      /*
        TODO: wrapAround looks better but it requires there to be enough
        slides that the "wrap around" is invisible to the user. A good
        implementation would detect if there aren't enough slides to wrap
        around and would in that case duplicate the slides. That's not very
        accessible though, so I'm not sure if it's worth it.

        Note that if wrapAround is false, the getters for nextIndex and
        previousIndex will need to be updated.
      */
      wrapAround: true,
    });

    // Fix for initial heights of slides being wrong when no explicit height set
    window.dispatchEvent(new Event("resize"));

    this.flickity.on("select", this.updateIndex.bind(this));
  }

  disconnect() {
    this.flickity.destroy();
  }

  refresh() {
    this.flickity.resize();
  }

  updateIndex(index) {
    this.indexValue = index;
  }

  indexValueChanged(index, previousIndex) {
    // Update button states
    if (this.hasNextButtonTarget) {
      if (this.nextIndex === false) {
        this.nextButtonTarget.setAttribute("disabled", "");
      } else {
        this.nextButtonTarget.removeAttribute("disabled");
      }
    }

    if (this.hasPreviousButtonTarget) {
      if (this.previousIndex === false) {
        this.previousButtonTarget.setAttribute("disabled", "");
      } else {
        this.previousButtonTarget.removeAttribute("disabled");
      }
    }

    if (this.hasNextButtonLabelTarget) {
      this.nextButtonLabelTarget.textContent = `Go to slide #${this.nextIndex + 1
        }`;
    }

    if (this.hasPreviousButtonLabelTarget) {
      this.previousButtonLabelTarget.textContent = `Go to slide #${this.previousIndex + 1
        }`;
    }

    if (this.hasAnnouncementTarget) {
      this.announcementTarget.textContent = `You are currently viewing slide ${index + 1
        } of ${this.slideTargets.length}`;
    }
  }

  next() {
    if (typeof this.nextIndex !== "number") {
      return;
    }

    return this.flickity.next();
  }

  previous() {
    if (typeof this.previousIndex !== "number") {
      return;
    }

    return this.flickity.previous();
  }

  get nextIndex() {
    if (this.indexValue === this.maxIndexValue) {
      return 0;
    } else {
      return this.indexValue + 1;
    }

    // Use if wrapAround is false:
    // if (this.maxIndexValue === 0) {
    //   return false;
    // } else if (this.indexValue === this.maxIndexValue) {
    //   return false;
    // } else {
    //   return this.indexValue + 1;
    // }
  }

  get previousIndex() {
    if (this.indexValue === this.maxIndexValue) {
      return 0;
    } else {
      return this.indexValue - 1;
    }

    // Use if wrapAround is false:
    // if (this.maxIndexValue === 0) {
    //   return false;
    // } else if (this.indexValue === 0) {
    //   return false;
    // } else {
    //   return this.indexValue - 1;
    // }
  }
}
