import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "backgroundImage"
  ];

  static values = {
    scrollPos: {
      type: Number,
    }
  };

  updatePos() {
    const rect = this.element.getBoundingClientRect();
    const top = rect.top;
    this.scrollPosValue = top;
  }

  connect() {

  }

  scrollPosValueChanged(current, previous) {
    if (this.scrollPosValue < 350) {
      this.backgroundImageTarget.classList.add("scrolled");
    }
    else {
      this.backgroundImageTarget.classList.remove("scrolled");
    }
  }
}
