import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "caption",
    "display",
    "image",
    "thumbnail",
  ];

  static values = {
    hover: false,
    open: false,
  };

  connect() {
  }

  toggle() {
    this.openValue = !this.openValue;
  }

  toggleHover() {
    this.hoverValue = !this.hoverValue;
  }

  hoverValueChanged() {
    if (this.hoverValue) {
      this.element.classList.add("hover");
    } else {
      this.element.classList.remove("hover");
    }
  }

  openValueChanged() {
    if (this.openValue) {
      this.element.classList.add("open");
      this.imageTarget.src = this.thumbnailTarget.src;
      document.body.classList.add("no-scroll");
    } else {
      this.element.classList.remove("open");
      this.imageTarget.src = "";
      document.body.classList.remove("no-scroll");
    }
  }
}
