import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "closeButton",
    "openButton",
    "nav",
  ];

  static values = {
    open: Boolean,
  }

  connect() {
  }

  toggle() {
    this.openValue = !this.openValue;
  }

  openValueChanged() {
    if (this.openValue) {
      this.element.classList.add("open");
    } else {
      this.element.classList.remove("open");
    }
  }
}
