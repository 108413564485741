import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if (this.element.tagName !== "svg") {
      console.warn("svg controller must be used on an <svg> element");
    }

    // NOTE: The edit form has tabs, so it's possible that when this
    // component `connect`s, it's not visible — so the stroke width
    // adjustment would fail. Using an IntersectionObserver allows us
    // to make the adjustment when we know it's visible.
    const observer = new IntersectionObserver(
      () => this.adjustStrokeWidth(),
      { threshold: [0, 1] }
    );
    observer.observe(this.element);
  }

  adjustStrokeWidth() {
    const { width } = this.element.getBoundingClientRect();
    const strokeWidth = this.viewBox.width / width;
    this.element.style.strokeWidth = `${strokeWidth}px`;
  }

  get viewBox () {
    return this.element.viewBox.baseVal;
  }
}