import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "pageHeader",
    "pageContent",
  ];

  connect() {
    this.isMobile = window.matchMedia("(max-width: 600px)").matches;
    if (this.isMobile) {
      return;
    }

    this.setPageOffset();
  }

  setPageOffset() {
    const pageHeaderHeight = this.pageHeaderTarget.getBoundingClientRect().height;
    const computedStyle = getComputedStyle(this.pageHeaderTarget);
    const marginTop = parseFloat(computedStyle.marginTop);
    const marginBottom = parseFloat(computedStyle.marginBottom);
    const totalPageHeaderHeight = pageHeaderHeight + marginTop + marginBottom;

    this.pageContentTarget.style.marginTop = `-${totalPageHeaderHeight}px`;
  }
}
