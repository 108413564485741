import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "form"
  ];

  connect() {
    this.toggleDetailsFromParams();
  }

  clearAndSubmit(event) {
    const form = this.formTarget;
    const selectedParam = event.target.name;
    const formData = new FormData(form);
    const searchParams = new URLSearchParams();

    searchParams.append(selectedParam, formData.get(selectedParam));

    const actionUrl = form.action.split('?')[0];
    const queryString = searchParams.toString();

    form.action = queryString ? `${actionUrl}?${queryString}#filter-anchor` : `${actionUrl}#filter-anchor`;

    form.submit();
  }

  toggleDetailsFromParams() {
    const details = document.querySelector(".news-item-index__filter-container");
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.toString()) {
      details.setAttribute("open", "open");
    } else {
      details.removeAttribute("open");
    }
  }
}
