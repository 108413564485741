import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "audio",
    "pauseButton",
    "playButton",
    "progressFill",
    "timeCode",
  ];
  static values = {
    duration: Number,
    paused: { type: Boolean, default: true },
    progress: Number,
  };

  connect() {
    this.isMobile = window.matchMedia("(pointer: coarse)").matches;
  }

  disconnect() {
  }

  pause(e) {
    this.dispatch("pause");
    this.pausedValue = true;
  }

  play(e) {
    this.dispatch("play");
    this.pausedValue = false;
  }

  playOrPause(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.pausedValue) {
      this.play();
    } else {
      this.pause();
    }
  }

  pausedValueChanged() {
    if (this.pausedValue) {
      this.audioTarget.pause();
      this.pauseButtonTarget.classList.add("hidden");
      this.playButtonTarget.classList.remove("hidden");
    } else {
      this.audioTarget.play();
      this.pauseButtonTarget.classList.remove("hidden");
      this.playButtonTarget.classList.add("hidden");
    }
  }

  recordDuration() {
    this.durationValue = this.audioTarget.duration;
    this.updateTimeCode(this.audioTarget.currentTime);
  }

  updateProgress() {
    this.progressValue = this.audioTarget.currentTime / this.audioTarget.duration;
    this.progressFillTarget.style.width = `${this.progressValue * 100}%`;
    this.updateTimeCode(this.audioTarget.currentTime);
  }

  updateTimeCode(currentTime) {
    const remainingTime = this.durationValue - currentTime;
    const formattedTime = this.formatTime(remainingTime);
    this.timeCodeTarget.textContent = `-${formattedTime}`;
  }

  formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    const paddedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
    return `${minutes}:${paddedSeconds}`;
  }
}
