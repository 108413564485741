import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "input",
    "map",
    "marker",
    "unset",
    "xLabel",
    "yLabel",
  ];
  static values = {
    location: Object,
    tempLocation: Object,
  };

  connect() {
    window.mapLocationEditor = this;
    this.locationValue = this.clampLocation(this.locationValue);
  }

  /*
  ::::::::::::::::::::
  :: PUBLIC METHODS ::
  ::::::::::::::::::::
  */

  clearTestLocation() {
    this.tempLocationValue = { x: null, y: null };
  }

  moveDown() {
    if (
      typeof this.locationValue?.x !== "number" ||
      typeof this.locationValue?.y !== "number"
    ) {
      return;
    }

    this.locationValue = this.clampLocation({
      ...this.locationValue,
      y: this.locationValue.y + 1,
    });
  }

  moveLeft() {
    if (
      typeof this.locationValue?.x !== "number" ||
      typeof this.locationValue?.y !== "number"
    ) {
      return;
    }

    this.locationValue = this.clampLocation({
      ...this.locationValue,
      x: this.locationValue.x - 1,
    });
  }

  moveRight() {
    if (
      typeof this.locationValue?.x !== "number" ||
      typeof this.locationValue?.y !== "number"
    ) {
      return;
    }

    this.locationValue = this.clampLocation({
      ...this.locationValue,
      x: this.locationValue.x + 1,
    });
  }

  moveUp() {
    if (
      typeof this.locationValue?.x !== "number" ||
      typeof this.locationValue?.y !== "number"
    ) {
      return;
    }

    this.locationValue = this.clampLocation({
      ...this.locationValue,
      y: this.locationValue.y - 1,
    });
  }

  pickLocation(event) {
    this.locationValue = this.clampLocation(
      this.getLocationForMousePosition(event)
    );
  }

  testLocation(event) {
    this.tempLocationValue = this.getLocationForMousePosition(event);
  }

  unset() {
    this.locationValue = undefined;
  }

  /*
  :::::::::::::::::::::::::::
  :: VALUE CHANGE HANDLERS ::
  :::::::::::::::::::::::::::
  */

  locationValueChanged(location) {
    if (
      location &&
      typeof location?.x == "number" &&
      typeof location?.y == "number"
    ) {
      this.markerTarget.style.left = `${
        ((location.x - 1) / this.data.get("columns")) * 100
      }%`;
      this.markerTarget.style.top = `${
        ((location.y - 1) / this.data.get("rows")) * 100
      }%`;
      this.markerTarget.style.opacity = 1;
      this.inputTarget.value = JSON.stringify(location);
      this.unsetTarget.style.opacity = 1;
    } else {
      this.markerTarget.style.opacity = 0;
      this.unsetTarget.style.opacity = 0;
      this.inputTarget.value = "";
    }

    this.renderLocationLabel(location);
  }

  tempLocationValueChanged(location) {
    this.renderLocationLabel(location);
  }

  /*
  :::::::::::::::::::::::
  :: RENDERING METHODS ::
  :::::::::::::::::::::::
  */

  renderLocationLabel(location) {
    this.xLabelTarget.innerText = location.x || this.locationValue?.x || "";
    this.yLabelTarget.innerText = location.y || this.locationValue?.y || "";
  }

  /*
  :::::::::::::
  :: HELPERS ::
  :::::::::::::
  */

  clampLocation(location) {
    return {
      x: Math.max(1, Math.min(location.x, this.data.get("columns"))),
      y: Math.max(1, Math.min(location.y, this.data.get("rows"))),
    };
  }

  getLocationForMousePosition(event) {
    const { clientX, clientY } = event;
    const { left, top, width, height } = this.mapTarget.getBoundingClientRect();
    const x = Math.ceil(((clientX - left) / width) * this.data.get("columns"));
    const y = Math.ceil(((clientY - top) / height) * this.data.get("rows"));
    return { x, y };
  }
}
