import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content", "inner", "introduction"];
  static outlets = ["map"];

  connect() {
    window.mapAnimation = this;
    if (!this.hasMapOutlet) {
      throw new Error('MapAnimationController requires a "map" outlet');
    }
    this.isConnected = true;
  }

  disconnect() {
    this.isConnected = false;
  }

  get shouldDestroyAfterNextRender() {
    return this.data.get("destroyAfterNextRender") === "true";
  }

  get durations() {
    if (!this._durations) {
      this._durations = JSON.parse(this.data.get("durations"));
    }
    return this._durations;
  }

  animate() {
    const { fadeIn, delay, textFade, textVisible, zoom, mapVisible, fadeOut } =
      this.durations;

    // NOTE: The entire MapAnimationController should be `opacity: 0`
    // at page load so that this setup work is invisible to the user.
    this.mapOutlet.hideControls({ duration: 0 });

    // Fade up everything from black
    this.mapOutlet
      .zoomOut({ duration: 0 })
      .then(() => {
        if (!this.isConnected) return;

        return this.element.animate([{ opacity: 0 }, { opacity: 1 }], {
          duration: fadeIn,
          delay: delay,
          fill: "forwards",
        }).finished;
      })
      .then(() => {
        if (!this.isConnected) return;

        // Fade out text
        return this.introductionTarget.animate(
          [{ opacity: 1 }, { opacity: 0 }],
          {
            duration: textFade,
            delay: textVisible,
            fill: "forwards",
          }
        ).finished;
      })
      .then(() => {
        if (!this.isConnected) return;

        return this.mapOutlet.zoomIn({
          duration: zoom,
          delay: mapVisible,
          easing: "cubic-bezier(0.42, 0, 0.58, 1)",
        });
      })
      .then(() => {
        if (!this.isConnected) return;

        this.mapOutlet.showControls();
        this.innerTarget.style.setProperty("display", "none");
        this.innerTarget.style.setProperty("touch-action", "none");
        this.innerTarget.style.setProperty("pointer-events", "none");
      });
  }

  // Triggered right before the page is cached by Turbo.
  destroy() {
    // If the map is set up to only show once in a time period, then
    // when a user uses the browser's back button to return to the homepage,
    // we don't want the animation to be present. Therefore, we remove the
    // `inner` element from the DOM before Turbo caches the page. This element
    // contains all of the visible elements that are animated.
    // Otherwise, if the map is set up to animate on every page load, then we
    // just want to unset some CSS properties.
    if (this.shouldDestroyAfterNextRender) {
      this.innerTarget.remove();
    } else {
      this.innerTarget.style.removeProperty("display");
      this.innerTarget.style.removeProperty("touch-action");
      this.innerTarget.style.removeProperty("pointer-events");
    }
  }
}
